import React from "react";
import {
  AutoAwesomeMosaic,
  Chat,
  Group,
  ShoppingCart,
  Category,
  LocalShipping,
  Inventory,
  Calculate,
  PriceChange,
  FindInPage,
} from "@mui/icons-material";

export const menuList = [
  {
    name: "회원관리",
    icon: <Group />,
    urlList: ["/user", "/seller"],
    children: [
      { url: "/user", name: "회원" },
      { url: "/seller", name: "파트너" },
    ],
  },
  {
    name: "상품관리",
    icon: <Inventory />,
    urlList: ["/product", "/recommend"],
    children: [
      { url: "/product", name: "상품" },
      { url: "/recommend", name: "빠른출고" },
    ],
  },
  {
    name: "주문관리",
    icon: <ShoppingCart />,
    urlList: ["/order", "/change", "/care"],
    children: [
      { url: "/order", name: "주문" },
      { url: "/change", name: "보상판매" },
      { url: "/care", name: "21 Care+" },
    ],
  },
  {
    name: "매입관리",
    icon: <LocalShipping />,
    urlList: ["/tradeIn", "/quote", "/cvs"],
    children: [
      { url: "/tradeIn", name: "중고폰 매입" },
      { url: "/quote", name: "중고폰 시세" },
      // { url: "/cvs", name: "편의점 현황" },
    ],
  },
  {
    name: "콘텐츠관리",
    icon: <AutoAwesomeMosaic />,
    urlList: ["/banner", "/event", "/blog", "exhibition", "/ep"],
    children: [
      { url: "/banner", name: "배너" },
      {
        url: "/event",
        name: "이벤트",
        // urlList: ["/event"],
        // children: [
        //   {
        //     url: "/event/middle",
        //     name: (
        //       <Grid container gap={1.5}>
        //         <Typography>•</Typography> MIDDLE
        //       </Grid>
        //     ),
        //   },
        //   {
        //     url: "/event/bottom",
        //     name: (
        //       <Grid container gap={1.5}>
        //         <Typography>•</Typography> BOTTOM
        //       </Grid>
        //     ),
        //   },
        // ],
      },
      { url: "/blog", name: "블로그" },
      { url: "/exhibition", name: "기획전" },
      { url: "/qna", name: "커뮤니티" },
      { url: "/ep", name: "EP 등록" },
    ],
  },
  { url: "/review", name: "리뷰관리", icon: <Chat /> },
  {
    name: "헤더관리",
    icon: <Category />,
    urlList: ["/menu", "/category", "/cvs"],
    children: [
      { url: "/menu", name: "메뉴" },
      { url: "/category", name: "카테고리" },
    ],
  },
  { url: "/calculate", name: "정산관리", icon: <Calculate /> },
  { url: "/price", name: "가격관리", icon: <PriceChange /> },
  {
    name: "SEO관리",
    icon: <FindInPage />,
    urlList: ["/plp", "/pdp"],
    children: [
      { url: "/plp", name: "PLP" },
      { url: "/pdp", name: "PDP" },
      { url: "/main", name: "메인" },
    ],
  },
];
